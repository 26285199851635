/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

// UI lib components

// UI local components
import { useScreenClass } from 'react-grid-system';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import ContactInfos from '../page-components/ContactUs/ContactInfos';
// import SpecilityForm from '../page-components/Application/SpecialityForm';

// styles
import '../page-styles/application.scss';
import ApplicationForm from '../page-components/Application/ApplicationForm';
import FormSuccessMsg from '../shared/Uikit/FormSuccessMsg';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Application() {
  /* ********************************** HOOKS ********************************* */
  const screenClass = useScreenClass();
  const [formData, setFormData] = React.useState({});
  const [step, setStep] = React.useState(0);

  let translateValue = -100;
  if (['xxl', 'xxxl'].includes(screenClass)) {
    translateValue = -60;
  } else if (['lg', 'xl'].includes(screenClass)) {
    translateValue = -70;
  }

  // Localization
  const { t } = useTranslation();

  const { successMessage } = t('formMessages', { returnObjects: true });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout variant="secondary" hideFooter>
      <Seo title={t('title')} />
      <div className="position-relative top-0 contact-page flex flex-column flex-lg-row bg-blue">
        <ContactInfos />

        {/* <div className="vw-60 vw-70-med h-100 bg-white f-center h-100 speciality-form min-vh-100">
          <div
            style={{ transform: `translateX(${translateValue * step}vw)` }}
            className="transition"
          >
            <SpecilityForm
              formData={formData}
              handleChange={handleChange}
              step={0}
              setStep={setStep}
            />
          </div>
        </div> */}
        <div className="flex bg-white pt-30-m h-100 min-vh-100">
          <div className="vw-60 vw-70-med min-vw-100-m h-100 bg-white f-center speciality-form pt-30-m">
            <div
              style={{
                transform: `translateX(${translateValue * step}vw)`,
              }}
              className="transition"
            >
              <ApplicationForm
                formData={formData}
                handleChange={handleChange}
                step={0}
                setStep={setStep}
              />
            </div>
          </div>
          <div
            className={`vw-60 vw-70-med min-vw-100-m bg-white f-center speciality-form ${
              step === 1 ? 'full-h' : ''
            }`}
          >
            <div
              style={{
                transform: `translateX(${translateValue * step}vw)`,
              }}
              className="transition"
            >
              <FormSuccessMsg message={successMessage} />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Application", "ContactUs", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Application;
