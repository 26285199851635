/* eslint-disable operator-linebreak */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLocation } from '@reach/router';

// Packages

// UI lib components

// UI local components
import FileInput from '../../../shared/Uikit/FileInput';
import Textarea from '../../../shared/Uikit/Textarea';
import TextInput from '../../../shared/Uikit/TextInput';
import Spinner from '../../../shared/Uikit/Spinner';

// styles
import './index.scss';

// assets
import {
  API_UPLOAD_FILE,
  APPLICATION_ENDPOINT,
} from '../../../shared/Helpers/api';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function ApplicationForm({ formData, handleChange, step, setStep }) {
  /* ********************************** HOOKS ********************************* */
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const offer = params.get('offer');
  const title = params.get('title');
  const [fieldErrors, setFieldErrors] = useState({});
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();

  const INVALID = 'INVALID';
  const TEL_TYPE = 'tel';
  const TEXT_TYPE = 'text';
  const EMAIL_TYPE = 'email';
  const FILE_TYPE = 'file';

  // Localization
  const { t } = useTranslation();
  const { subtitle, form, buttonText } = t('application', {
    returnObjects: true,
  });

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    try {
      let isValid = true;
      const validationErrors = {};
      form.elements.forEach(({ name, type, required }) => {
        if (!required) return;
        if (type === TEL_TYPE) {
          const regex =
            /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
          if (!formData[name]?.match(regex)) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        } else if (type === EMAIL_TYPE) {
          const regex =
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
          if (!formData[name]?.match(regex)) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        } else if (type === TEXT_TYPE) {
          if (!formData[name]?.trim()?.length) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        } else if (type === FILE_TYPE) {
          if (!formData[name]) {
            isValid = false;
            validationErrors[name] = INVALID;
          }
        }
      });
      setFieldErrors(validationErrors);
      if (isValid) {
        const fileData = new FormData();
        fileData.append('files', file);
        const { data } = await axios.post(API_UPLOAD_FILE, fileData);
        await axios.post(APPLICATION_ENDPOINT, {
          data: { ...formData, file: data[0]?.id, offer },
        });
        setResponse('SUCCESS');
        setStep(step + 1);
      } else {
        setFieldErrors(validationErrors);
      }
    } catch (error) {
      setResponse('ERROR');
      setLoading(false);
    }
    setLoading(false);
  };

  const onChange = (e) => {
    if (fieldErrors[e.target.name]) {
      setFieldErrors({ ...fieldErrors, [e.target.name]: undefined });
    }
    handleChange(e);
    setResponse();
  };

  /* ******************************** RENDER HELPERS ******************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <div className="flex flex-column py-70 py-70-med p-0-m">
      <span className="bold f24 text-darkBlue mb-42 mb-30-med">
        {title ?? subtitle}
      </span>
      <form>
        <Row>
          {form.elements.map(({ name, type, placeholder, id, span }) => {
            if (type === 'file') {
              return (
                <Col lg={span} key={name}>
                  <div className="mb-30">
                    <FileInput
                      name={name}
                      id={id}
                      type={type}
                      placeholder={placeholder}
                      value={formData[name] ?? ''}
                      onChange={onChange}
                      fieldError={fieldErrors[name] === INVALID}
                      setFile={setFile}
                    />
                  </div>
                </Col>
              );
            }
            if (type === 'textarea') {
              return (
                <Col lg={span} key={name}>
                  <div className="mb-30">
                    <Textarea
                      name={name}
                      id={id}
                      type={type}
                      placeholder={placeholder}
                      value={formData[name] ?? ''}
                      onChange={onChange}
                    />
                  </div>
                </Col>
              );
            }
            return (
              <Col lg={span} key={name}>
                <div className="mb-30">
                  <TextInput
                    name={name}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={formData[name] ?? ''}
                    onChange={onChange}
                    fieldError={fieldErrors[name] === INVALID}
                  />
                </div>
              </Col>
            );
          })}
          <Col lg={12}>
            <button
              type="button"
              className="f-center bg-dark border-none outline-none p-24 bg-darkBlue text-white f16 br8 bold cursor-pointer w-100 mt-30 m-0-m position-relative"
              onClick={handleSubmit}
            >
              {loading ? <Spinner /> : buttonText}
            </button>
          </Col>
        </Row>
      </form>
      <div className="mt-48 mt-30-m">
        {response && (
          <div
            className={`${response === 'ERROR' ? 'error-msg' : ''} ${
              response === 'SUCCESS' ? 'success-msg' : ''
            } align-c`}
          >
            {response === 'SUCCESS' ? '' : ''}
            {response === 'ERROR' ? "un message d'erreur s'est produit" : ''}
          </div>
        )}
      </div>
    </div>
  );
}

ApplicationForm.propTypes = {
  formData: PropTypes.shape.isRequired,
  step: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};

export default ApplicationForm;
