import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import fileIcon from '../../../images/form/file.svg';

import './index.scss';

function FileInput({
  name,
  id,
  placeholder,
  type,
  value,
  onChange,
  fieldError,
  setFile,
  file,
}) {
  // const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const handleChange = (e) => {
    const currentFile = e.target.files[0];
    setFileName(currentFile.name);
    setFile(currentFile);
    onChange(e);
  };
  useEffect(() => {
    if (!file) {
      setFileName();
    }
  }, [file]);
  return (
    <label
      htmlFor={id}
      className={`file-input position-relative w-100 h-72 cursor-pointer px-30 br18 f-v-center ${
        fieldError ? 'error' : ''
      }`}
    >
      <span className="placeholder position-absolute w-100 f-center text-blue-1 light">
        <img src={fileIcon} alt="" className="me-10" />
        {fileName || placeholder}
      </span>
      <input
        name={name}
        id={id}
        type={type}
        className="border-none outline-none bg-transparent w-100 text-darkBlue"
        value={value}
        onChange={handleChange}
        hidden
      />
    </label>
  );
}

FileInput.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  fieldError: PropTypes.bool,
  onChange: PropTypes.func,
  setFile: PropTypes.func,
  file: PropTypes.shape,
};

FileInput.defaultProps = {
  name: '',
  id: '',
  placeholder: '',
  type: 'text',
  value: '',
  fieldError: false,
  file: undefined,
  onChange: () => {},
  setFile: () => {},
};

export default FileInput;
